<script setup lang="ts">
import EVENTS, { SECTIONS } from '~/constants/events';
import { useUpdateLastSeenRenewBanner } from '~/features/auth';

const { $trackEvent } = useNuxtApp();
const { shouldShowRenewBanner, openUpsellModal } = usePremiumCheck();
const { uid } = useUserState();
const { updateLastSeen } = useUpdateLastSeenRenewBanner(uid.value);
const route = useRoute();
const router = useRouter();

const onClickRenew = () => {
  openUpsellModal(SECTIONS.WALLET_NOTIFICATION);

  $trackEvent(EVENTS.RENEW_PREMIUM_NOTIFICATION_CLICKED, {
    page: route.meta.eventPageName,
  });
};

router.afterEach(() => {
  if (shouldShowRenewBanner.value) {
    updateLastSeen();
  }
});
</script>

<template>
  <div
    v-if="shouldShowRenewBanner"
    class="font-secondary flex max-w-[365px] items-center justify-between space-x-4 p-3 text-xs font-semibold"
  >
    <div>
      <h4
        class="font-poppins mb-1 stroke-black text-base font-black uppercase text-white drop-shadow-[1px_3px_black]"
      >
        Renew for 25% off
      </h4>
      <p>
        You don't have an active subscription. Renew to participate in the next
        sample cycle.
      </p>
    </div>
    <div>
      <UButton
        color="secondary"
        variant="solid"
        size="sm"
        :ui="{ padding: { sm: 'px-2 py-1 sm:px-4 sm:py-1.5' } }"
        @click="onClickRenew"
      >
        Renew
      </UButton>
    </div>
  </div>
</template>
