import { useLastSeenBannerMutation } from '../../mutations';

export function useUpdateLastSeenRenewBanner(uid?: string | null) {
  const mutation = useLastSeenBannerMutation(uid);

  const updateLastSeen = () => {
    mutation.mutate();
  };

  return {
    updateLastSeen,
    isUpdating: mutation.isPending,
    error: mutation.error,
  };
}
