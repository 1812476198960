import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { updateLastSeenRenewBanner } from '../api';
import type { UserDataResponse } from '@tn/shared';

export const useLastSeenBannerMutation = (uid?: string | null) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      if (!uid) {
        throw new Error('User ID is required to update last seen banner');
      }
      return await updateLastSeenRenewBanner(uid);
    },
    onSuccess: (data) => {
      const previousData = queryClient.getQueryData<UserDataResponse>(['me']);

      if (previousData) {
        queryClient.setQueryData<UserDataResponse>(['me'], {
          ...previousData,
          lastSeenRenewBannerMs: data.now,
        });
      }

      return data.now;
    },
  });
};
